.cta_list {
  .cta {
    &__text {
      width: 100%;

      color: var(--section-text-color-secondary);
      font: var(--font-body-M);
    }  
    
    &-form {
      @include mediaLaptop {
        gap: rem(35);
      }

      @include mediaMobile {
        gap: rem(30);
      }

      &__content {
        @include mediaLaptop {
          gap: rem(20);
        }

        @include mediaMobile {
          gap: rem(15);
        }
      }
    }

    &__image {
      @include mediaMobile {
        display: flex;
        height: rem(320);
      }
    }
  }
}
