@import '../../mixins';

.cta {
  --cta-padding-top: #{rem(110)};
  --cta-padding-bottom: #{rem(110)};
  
  --cta-background: var(--bg-light);

  overflow: hidden;

  @include mediaDesktop {
    --cta-padding-top: #{rem(150)};
    --cta-padding-bottom: #{rem(150)};
  }

  @include mediaBigDesktop {
    --cta-padding-top: #{big(150)};
    --cta-padding-bottom: #{big(150)};
  }

  @include mediaLaptop {
    --cta-padding-top: #{rem(70)};
    --cta-padding-bottom: #{rem(80)};
  }

  @include mediaTablet {
    --cta-padding-top: #{rem(50)};
    --cta-padding-bottom: #{rem(60)};
  }

  @include mediaMobile {
    --cta-padding-top: #{rem(40)};
    --cta-padding-bottom: #{rem(40)};
  }

  &__content {
    @include mediaTablet {
      position: relative;
      right: var(--container-offset);

      width: calc(100% + (var(--container-offset) * 2));
    }
  }

  &__wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: stretch;
    justify-content: stretch;

    @include mediaDesktop {
      grid-template-columns: rem(936) 1fr;
    }

    @include mediaBigDesktop {
      grid-template-columns: big(936) 1fr;
    }

    @include mediaMobile {
      display: flex;
      flex-direction: column;
    }
  }

  &-form {
    padding: var(--cta-padding-top) calc(var(--container-secondary-offset) - var(--container-offset)) var(--cta-padding-bottom);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(40);

    background-color: var(--cta-background);

    @include mediaBigDesktop {
      padding-top: big(110);
      padding-bottom: big(110);
      gap: big(40);
    }

    @include mediaLaptop {
      grid-area: rem(30);
    }

    @include mediaTablet {
      padding: var(--cta-padding-top) var(--container-offset) var(--cta-padding-bottom);
      gap: rem(15);
    }

    @include mediaMobile {
      gap: rem(20);
    }

    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      gap: rem(30);

      @include mediaBigDesktop {
        gap: big(30);
      }

      @include mediaLaptop {
        gap: rem(40);
      }

      @include mediaTablet {
        gap: rem(25);
      }

      @include mediaMobile {
        gap: rem(20);
      }
    }

    &__input {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      gap: rem(20);

      @include mediaBigDesktop {
        gap: big(20);
      }

      @include mediaTablet {
        gap: rem(10);
      }
    }

    &__collapse {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: stretch;
      flex-grow: 1;
      gap: rem(20);

      @include mediaBigDesktop {
        gap: big(20);
      }

      @include mediaTablet {
        gap: rem(10);
      }
    }

    &__submit {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      gap: rem(40);

      @include mediaBigDesktop {
        gap: big(40);
      }

      @include mediaLaptop {
        flex-direction: column-reverse;
        align-items: start;
        justify-content: start;
        gap: rem(30);
      }

      @include mediaMobile {
        gap: rem(20);
      }

      & .button {
        flex-shrink: 0;

        @include mediaMobile {
          width: 100%;
        }
      }
    }

    &__policy {
      color: var(--section-text-color-secondary);
      font: var(--font-body-XS);

      & a {
        color: var(--accent-light-primary);
        text-decoration: none;

        transition: color var(--animation-timing) var(--cubic-bezier);

        cursor: pointer;

        @include hover {
          color: var(--accent-light-secondary);
        }
      }
    }

    & .text-input {
      width: 100%;

      &__input {
        width: 100%;
      }

      & textarea {
        height: rem(50);

        @include mediaBigDesktop {
          height: big(50);
        }

        @include mediaLaptop {
          height: rem(90);
        }
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: auto;

    overflow: hidden;

    user-select: none;
    pointer-events: none;
    
    @include mediaDesktop {
      aspect-ratio: 15/10;
      
      @supports not (aspect-ratio:1/1) {
        height: auto;
      }
    }

    @include mediaMobile {
      display: none;
    }

    & img,
    & picture {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background-color: var(--bg-light-extra);

      object-fit: cover;
      object-position: center;
    }
  }
}

@import "cta-list", "cta-short";
