.cta_short {
  .cta {
    &__wrapper {
      padding: rem(90) rem(150) rem(100);
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: var(--grid-column-gap);
      row-gap: var(--grid-row-gap);

      background-color: var(--cta-background);

      @include mediaBigDesktop {
        padding: big(90) big(150) big(100);
      }

      @include mediaLaptop {
        padding: rem(70) rem(103) rem(80);
      }

      @include mediaTablet {
        padding: rem(50) rem(45) rem(60);
        column-gap: rem(40);
      }

      @include mediaMobile {
        padding: rem(40) var(--container-offset);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        row-gap: rem(20);
      }
    }

    &__column {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      flex-grow: 1;

      &_left {
        padding-right: rem(40);

        @include mediaBigDesktop {
          padding-right: big(40);
        }

        @include mediaLaptop {
          padding-right: rem(30);
        }

        @include mediaMobile {
          padding-right: 0;
        }
      }
    }

    &__info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: rem(30);

      @include mediaBigDesktop {
        gap: big(30);
      }

      @include mediaTablet {
        gap: rem(15);
      }

      @include mediaMobile {
        gap: rem(10);
      }
    }

    &__title {
      width: 100%;

      color: var(--section-text-color-primary);
    }

    &__text {
      width: 100%;

      color: var(--section-text-color-secondary);
      font: var(--font-body-M);
    }

    &-form {
      padding: 0;
      width: 100%;
      gap: rem(40);

      background-color: transparent;

      @include mediaBigDesktop {
        gap: big(40);
      }

      @include mediaLaptop {
        gap: rem(30);
      }

      @include mediaMobile {
        gap: rem(20);
      }

      &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: rem(30);

        @include mediaBigDesktop {
          gap: big(30);
        }

        @include mediaLaptop {
          gap: rem(20);
        }

        @include mediaTablet {
          gap: rem(15);
        }
      }

      &__collapse {
        width: 100%;

        @include media(#{rem(380)}) {
          flex-direction: column;
        }
      }

      & .button {
        @include mediaMobile {
          width: 100%;
        }
      }
    }
  }
}
